import Firestore from 'api/firebase';
import { Candlestick, Diff, Holding, Quote } from 'shared/types';

// Candlesticks related.
export async function GetCandlesticksByTicker(
  ticker: string
): Promise<Candlestick[]> {
  const snapshot = Firestore.collection('candlesticks').doc(ticker);
  const doc = await snapshot.get();

  if (!doc.exists) {
    console.log('No such document!');
  } else {
    const data = doc.data();
    if (!data) {
      console.log('This is not expected');
    } else {
      return data['ytd'] as Candlestick[];
    }
  }

  return [];
}

// Diffs related.
export async function GetDiffsByDate(date: string): Promise<Diff[]> {
  const snapshot = Firestore.collection('daily_diffs').doc(date);
  const doc = await snapshot.get();

  if (!doc.exists) {
    console.log('No such document!');
  } else {
    const data = doc.data();
    if (!data) {
      console.log('This is not expected');
    } else {
      const rawData = data[date];
      // Collection in Firestore is off format [{ 'TSLA': { ... }, ... }],
      // so we remove the extraneous 'TSLA' key below.
      return rawData.map(
        (rawElement: any) => rawElement[Object.keys(rawElement)[0]] as Diff
      );
    }
  }

  return [];
}

export async function GetDiffsByTicker(ticker: string): Promise<Diff[]> {
  const snapshot = Firestore.collection('diffs').doc(ticker);
  const doc = await snapshot.get();

  if (!doc.exists) {
    console.log('No such document!');
  } else {
    const documentData = doc.data() || {};
    const keys = Object.keys(documentData);
    if (keys.length !== 1) {
      console.log('This is not expected');
    }
    return documentData[ticker] as Diff[];
  }

  return [];
}

// Holdings related.
export async function GetHoldingsLatest(): Promise<Holding[]> {
  const snapshot = Firestore.collection('holdings').doc('latest');
  const doc = await snapshot.get();

  if (!doc.exists) {
    console.log('No such document!');
  } else {
    const documentData = doc.data() || {};
    const keys = Object.keys(documentData);
    if (keys.length !== 1) {
      console.log('This is not expected');
    }
    const date = keys.pop(); // TODO: return this if needed
    if (date) {
      return documentData[date] as Holding[];
    }
  }

  return [];
}

// Quotes related.
export async function GetQuote(ticker: string): Promise<Quote | null> {
  const snapshot = Firestore.collection('quotes').doc(ticker);
  const doc = await snapshot.get();

  if (!doc.exists) {
    console.log('No such document!');
  } else {
    const documentData = doc.data() || {};
    const keys = Object.keys(documentData);
    if (keys.length <= 0) {
      console.log('This is not expected');
    } else {
      return documentData as Quote;
    }
  }

  return null;
}

export async function SetQuote(ticker: string, quote: Quote) {
  const snapshot = Firestore.collection('quotes').doc(ticker);
  await snapshot.set(quote);
}
