import 'antd/dist/antd.css';
import DiffsPage from 'pages/Diffs';
import HoldingsPage from 'pages/Holdings';
import TickerPage from 'pages/Ticker';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from 'shared/routes';
import type {} from 'styled-components/cssprop';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.root} component={HoldingsPage} />
        <Route exact path={Routes.diffs} component={DiffsPage} />
        <Route exact path={Routes.ticker} component={TickerPage} />
        <Route>
          <Redirect to={Routes.root} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
