import { format, parse } from 'date-fns';

export function formatDateStringChartApi(dateString: string) {
  return format(parse(dateString, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd');
}

export function formatDateStringUser(dateString: string) {
  return format(
    parse(dateString, 'yyyy-MM-dd', new Date()),
    'EEEE, MMMM yyyy (MM/dd/yyyy)'
  );
}
