import ReactEcharts from 'echarts-for-react';
import { CandlestickDiffPair, createCandlestickDiffPairs } from 'shared/charts';
import { formatDateStringChartApi } from 'shared/dates';
import { formatShareCount } from 'shared/numbers';
import { Candlestick, Diff } from 'shared/types';
import styled from 'styled-components';

const upBorderColor = 'rgba(39, 174, 96,1.0)';
const downBorderColor = 'rgba(192, 57, 43,1.0)';

enum Colors {
  New = 'rgba(52, 152, 219,1.0)',
  Buy = 'rgba(46, 204, 113, 1.0)',
  Sell = 'rgba(231, 76, 60, 1.0)',
  Exit = 'rgba(230, 126, 34, 1.0)',
  Unknown = 'rgba(103, 128, 159, 1.0)',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const TitleText = styled.h3`
  font-weight: 700;
`;

const Legend = styled.div`
  display: flex;
`;

interface LegendItemProps {
  color: string;
}

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const LegendItemSpace = styled.div`
  width: 16px;
`;

const LegendItemColor = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ color }: LegendItemProps) => color};
`;

const LegendItemText = styled.span`
  margin-left: 8px;
  color: ${({ color }: LegendItemProps) => color};
  font-size: 16px;
`;

function categorizeData(candlesticks: Candlestick[]) {
  const categoryData = [];
  const values = [];
  const volumes = [];
  for (var i = 0; i < candlesticks.length; i++) {
    const candlestick = candlesticks[i];
    categoryData.push(formatDateStringChartApi(candlestick.date));
    values.push([
      candlestick.open,
      candlestick.close,
      candlestick.low,
      candlestick.high,
    ]);
    volumes.push([
      i,
      candlestick.volume,
      candlestick.open < candlestick.close ? -1 : 1,
    ]);
  }
  return {
    categoryData: categoryData,
    values: values,
    volumes: volumes,
  };
}

function getColorForAction(action: string) {
  switch (action) {
    case 'New':
      return Colors.New;
    case 'Buy':
      return Colors.Buy;
    case 'Sell':
      return Colors.Sell;
    case 'Exit':
      return Colors.Exit;
    default:
      return Colors.Unknown;
  }
}

function computeMarkPointsData(candlestickDiffPairs: CandlestickDiffPair[]) {
  return candlestickDiffPairs
    .filter(
      ({ candlestick, diff }) =>
        diff && ['Buy', 'New', 'Sell', 'Exit'].includes(diff.action)
    )
    .map(({ candlestick, diff }) => ({
      name: diff.action,
      coord: [
        formatDateStringChartApi(diff.date),
        ['Buy', 'New'].includes(diff.action)
          ? candlestick.low
          : candlestick.high,
      ],
      label: {
        position: ['Buy', 'New'].includes(diff.action) ? 'bottom' : 'top',
        color: 'inherit',
        fontWeight: 'bold',
      },
      // TODO: handle NaN and create special color for 'New'.
      value: `${
        (diff.normalized_share_weight_diff || diff.share_weight) > 0 ? '+' : ''
      }${(
        (diff.normalized_share_weight_diff || diff.share_weight) * 100
      ).toFixed(2)}%\n${
        (diff.normalized_share_weight_diff || diff.share_weight) > 0 ? '+' : '-'
      }${formatShareCount(diff.daily_share_diff || diff.shares)}`,
      symbolOffset: [0, 0],
      symbolRotate: ['Buy', 'New'].includes(diff.action) ? 180 : 0,
      symbolSize: 50,
      itemStyle: {
        color: getColorForAction(diff.action),
      },
    }));
}

interface Props {
  height: number;
  title: string;
  candlesticks: Candlestick[];
  diffs: Diff[];
}

function CandlestickChart({ height, title, candlesticks, diffs }: Props) {
  const data = categorizeData(candlesticks);
  const candlestickDiffPairs = createCandlestickDiffPairs(candlesticks, diffs);
  const markPointsData = computeMarkPointsData(candlestickDiffPairs);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    visualMap: {
      show: false,
      seriesIndex: 1,
      dimension: 2,
      pieces: [
        {
          value: 1,
          color: Colors.Sell,
        },
        {
          value: -1,
          color: Colors.Buy,
        },
      ],
    },
    grid: [
      {
        containLabel: false,
        left: 36,
        right: 12,
        top: '5%',
        height: '60%',
      },
      {
        containLabel: false,
        left: 36,
        right: 12,
        top: '75%',
        height: '10%',
      },
    ],
    xAxis: [
      {
        type: 'category',
        gridIndex: 0,
        data: data.categoryData,
        scale: true,
        boundaryGap: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax',
        axisPointer: {
          z: 100,
        },
      },
      {
        type: 'category',
        gridIndex: 1,
        data: data.categoryData,
        scale: true,
        boundaryGap: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax',
      },
    ],
    yAxis: [
      {
        scale: true,
        gridIndex: 0,
        axisLabel: {
          // eslint-disable-next-line no-template-curly-in-string
          formatter: '${value}',
        },
        splitArea: {
          show: true,
        },
      },
      {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
        start: 0,
        end: 100,
      },
      {
        show: true,
        type: 'slider',
        xAxisIndex: [0, 1],
        top: '90%',
        height: '8%',
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: 'Price',
        type: 'candlestick',
        data: data.values,
        itemStyle: {
          color: Colors.Buy,
          color0: Colors.Sell,
          borderColor: upBorderColor,
          borderColor0: downBorderColor,
        },
        markPoint: {
          label: {
            formatter: function (param: any) {
              return param != null ? param.value : '';
            },
          },
          data: markPointsData,
          tooltip: {
            formatter: function (param: any) {
              return param.name + '<br>' + (param.data.coord || '');
            },
          },
        },
      },
      // {
      //   name: 'MA5',
      //   type: 'line',
      //   data: calculateMA(5),
      //   smooth: true,
      //   lineStyle: {
      //     opacity: 0.5,
      //   },
      // },
      // {
      //   name: 'MA10',
      //   type: 'line',
      //   data: calculateMA(10),
      //   smooth: true,
      //   lineStyle: {
      //     opacity: 0.5,
      //   },
      // },
      // {
      //   name: 'MA20',
      //   type: 'line',
      //   data: calculateMA(20),
      //   smooth: true,
      //   lineStyle: {
      //     opacity: 0.5,
      //   },
      // },
      // {
      //   name: 'MA30',
      //   type: 'line',
      //   data: calculateMA(30),
      //   smooth: true,
      //   lineStyle: {
      //     opacity: 0.5,
      //   },
      // },
      {
        name: 'Volume',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: data.volumes,
      },
    ],
  };

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <Legend>
        <LegendItem>
          <LegendItemColor color={Colors.New} />
          <LegendItemText color={Colors.New}>New</LegendItemText>
        </LegendItem>
        <LegendItemSpace />
        <LegendItem>
          <LegendItemColor color={Colors.Buy} />
          <LegendItemText color={Colors.Buy}>Buy</LegendItemText>
        </LegendItem>
        <LegendItemSpace />
        <LegendItem>
          <LegendItemColor color={Colors.Sell} />
          <LegendItemText color={Colors.Sell}>Sell</LegendItemText>
        </LegendItem>
        <LegendItemSpace />
        <LegendItem>
          <LegendItemColor color={Colors.Exit} />
          <LegendItemText color={Colors.Exit}>Exit</LegendItemText>
        </LegendItem>
      </Legend>
      <ReactEcharts
        notMerge
        lazyUpdate
        theme={'theme_name'}
        option={option}
        style={{
          width: '100%',
          height,
        }}
      />
    </Container>
  );
}

export default CandlestickChart;
