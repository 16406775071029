import ReactEcharts from 'echarts-for-react';
import { createCandlestickDiffPairs } from 'shared/charts';
import { formatDateStringChartApi } from 'shared/dates';
import { Candlestick, Diff } from 'shared/types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const TitleText = styled.h3`
  font-weight: 700;
`;

interface Props {
  height: number;
  title: string;
  candlesticks: Candlestick[];
  diffs: Diff[];
}

function LineChart({ height, title, candlesticks, diffs }: Props) {
  const colors = ['#5470C6', '#91CC75'];
  const candlestickDiffPairs = createCandlestickDiffPairs(candlesticks, diffs);
  const shareWeights = candlestickDiffPairs.map(
    ({ candlestick, diff }) => (diff ? diff.share_weight : 0) * 100
  );
  const closePrices = candlesticks.map((candlestick) => candlestick.close);

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      containLabel: false,
      left: 36,
      right: 12,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: candlesticks.map((candlestick) =>
        formatDateStringChartApi(candlestick.date)
      ),
    },
    yAxis: [
      {
        scale: true,
        gridIndex: 0,
        type: 'value',
        name: '% of Portfolio',
        min: 0,
        position: 'left',
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[0],
          },
        },
        axisLabel: {
          formatter: '{value}%',
        },
      },
      {
        scale: true,
        gridIndex: 0,
        splitNumber: 2,
        type: 'value',
        name: 'Price',
        position: 'right',
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[1],
          },
        },
        axisLabel: {
          // eslint-disable-next-line no-template-curly-in-string
          formatter: '${value}',
        },
      },
    ],
    series: [
      {
        name: '% of Portfolio',
        type: 'bar',
        yAxisIndex: 0,
        data: shareWeights,
      },
      {
        name: 'Price',
        type: 'line',
        yAxisIndex: 1,
        data: closePrices,
      },
    ],
  };

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <ReactEcharts
        notMerge
        lazyUpdate
        option={option}
        style={{
          width: '100%',
          height,
        }}
      />
    </Container>
  );
}

export default LineChart;
