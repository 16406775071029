import { Candlestick, Diff } from 'shared/types';

export type CandlestickDiffPair = {
  date: string;
  candlestick: Candlestick;
  diff: Diff;
};

export type DateToDiff = {
  [date: string]: Diff;
};

export function createCandlestickDiffPairs(
  candlesticks: Candlestick[],
  diffs: Diff[]
) {
  const dateToDiff = {} as DateToDiff;
  diffs.forEach((diff) => (dateToDiff[diff.date] = diff));
  return candlesticks.map(
    (candlestick) =>
      ({
        date: candlestick.date,
        candlestick: candlestick,
        diff: dateToDiff[candlestick.date],
      } as CandlestickDiffPair)
  );
}
