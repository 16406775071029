import { SearchOutlined } from '@ant-design/icons';
import AlphaVantage from 'alphavantage';
import { Input, Radio, Table, Tag } from 'antd';
import Page from 'components/Page/Page';
import QuoteCard from 'components/Quote/QuoteCard';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { GetHoldingsLatest, GetQuote, SetQuote } from 'shared/api';
import { TickerToCompanyUrl } from 'shared/companies';
import { formatCurrency, formatPercentage } from 'shared/numbers';
import { Routes } from 'shared/routes';
import { Holding, Quote } from 'shared/types';
import styled from 'styled-components';

const alpha = AlphaVantage({ key: 'Y4OSK5SWMTYK0AJ1' });

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
  max-width: 1024px;
  padding-bottom: 64px;
`;

interface SectionProps {
  hasPadding?: boolean;
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  ${({ hasPadding = true }: SectionProps) => hasPadding && 'padding: 0px 16px;'}
`;

const SectionSpace = styled.div`
  height: 24px;
`;

interface RowProps {
  isDesktopOrLaptop: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: ${({ isDesktopOrLaptop }: RowProps) =>
    isDesktopOrLaptop ? 'row' : 'column'};
  justify-content: space-between;
  align-items: center;
`;

const RowSpace = styled.div`
  width: 24px;
  height: 24px;
`;

const Search = styled.div`
  flex: 1;
  align-self: stretch;
`;

const TableWrapper = styled.div`
  padding: 0px 16px;
`;

const TickerText = styled.span`
  font-weight: 500;
`;

const CompanyCell = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyText = styled.span`
  margin-left: 8px;
`;

const Funds = ['ARKK', 'ARKQ', 'ARKW', 'ARKG', 'ARKF', 'PRNT', 'IZRL'];

type FundToQuote = {
  [fund: string]: Quote | null;
};

async function fetchCachedQuoteByFund(fund: string) {
  let quote = await GetQuote(fund);
  const now = Date.now();
  return {
    isStale: !quote || now - quote.updated_at > 5 * 60 * 1000,
    quote,
  };
}

async function fetchLatestQuoteByFund(fund: string, fetchCount: number) {
  if (fetchCount < 5) {
    const response = await alpha.data.quote(fund);
    // change: '-1.4600';
    // change_percent: '-0.9324%';
    // high: '159.7000';
    // latest_trading_day: '2021-02-16';
    // low: '154.7300';
    // open: '159.0500';
    // prev_close: '156.5800';
    // price: '155.1200';
    // symbol: 'ARKK';
    // volume: '11148788';
    const rawQuote = alpha.util.polish(response).data;
    const formattedQuote = {
      ticker: fund,
      price: parseFloat(rawQuote.price),
      open: parseFloat(rawQuote.open),
      low: parseFloat(rawQuote.low),
      high: parseFloat(rawQuote.high),
      volume: parseFloat(rawQuote.volume),
      updated_at: Date.now(),
    } as Quote;
    SetQuote(fund, formattedQuote);
    return { didFetch: true, quote: formattedQuote };
  } else {
    return { didFetch: false, quote: null };
  }
}

function getSearchIndexTicker(holding: Holding, searchQuery: string) {
  return holding.ticker.toLowerCase().indexOf(searchQuery.toLowerCase());
}

function getSearchIndexCompany(holding: Holding, searchQuery: string) {
  return holding.company.toLowerCase().indexOf(searchQuery.toLowerCase());
}

function HoldingsPage() {
  useTitle('Ark Invest Holdings Tracker');

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [fundToQuote, setFundToQuote] = useState<FundToQuote>({});

  // On component did load, fetch quotes of all ARK funds.
  // If quotes are up-to-date (within 5 minutes) in Firestore, use those.
  // Otherwise, ping AlphaVantage for latest quotes and record
  // these quotes in Firestore.
  useEffect(() => {
    const getQuotesByFunds = async () => {
      const fundToQuote = {} as FundToQuote;
      const staleFunds = [] as string[];

      // Pass 1: fetch cached quotes from Firestore and record which quotes are stale.
      for (const fund of Funds) {
        const { isStale, quote } = await fetchCachedQuoteByFund(fund);
        fundToQuote[fund] = quote;
        if (isStale) {
          staleFunds.push(fund);
        }
      }

      setFundToQuote(fundToQuote);

      // Pass 2: for stale quotes, fetch latest data from AlphaVantage API.
      let fetchCount = 0;

      for (const fund of staleFunds) {
        const { didFetch, quote } = await fetchLatestQuoteByFund(
          fund,
          fetchCount
        );
        if (didFetch) {
          fetchCount += 1;
          fundToQuote[fund] = quote;
        }
      }

      setFundToQuote(fundToQuote);
    };

    getQuotesByFunds();
  }, []);

  useEffect(() => {
    const fetchHoldings = async () => {
      const holdings = await GetHoldingsLatest();
      setHoldings(holdings);
    };
    fetchHoldings();
  }, []);

  const [selectedFund, setSelectedFund] = useState(Funds[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = useMemo(
    () => [
      {
        key: 'index',
        title: '#',
        dataIndex: 'index',
      },
      {
        key: 'ticker',
        title: 'Ticker',
        dataIndex: 'ticker',
        render: (value: string, record: Holding) => {
          return (
            <Link
              to={Routes.ticker.replace(
                ':ticker',
                `${record.fund as string}-${value as string}`
              )}
            >
              <TickerText>{(value || '') as string}</TickerText>
            </Link>
          );
        },
      },
      {
        key: 'company',
        title: 'Company',
        dataIndex: 'company',
        render: (value: string, record: Holding) => {
          return (
            <CompanyCell>
              <img
                src={
                  TickerToCompanyUrl[record.ticker]
                    ? `//logo.clearbit.com/${
                        TickerToCompanyUrl[record.ticker]
                      }?size=24`
                    : ''
                }
                alt={''}
              />
              <CompanyText>{value}</CompanyText>
            </CompanyCell>
          );
        },
      },
      {
        key: 'fund',
        title: 'Fund',
        dataIndex: 'fund',
        render: (value: string) => <Tag color={'geekblue'}>{value}</Tag>,
      },
      {
        key: 'market_value',
        title: 'Market Value',
        dataIndex: 'market_value',
        render: (value: number) => formatCurrency(value),
      },
      {
        key: 'market_value_weight',
        title: 'Market Value Weight',
        dataIndex: 'mv_weight',
        render: (value: number) => formatPercentage(value),
      },
      {
        key: 'share_weight',
        title: 'Share Weight',
        dataIndex: 'share_weight',
        render: (value: number) => formatPercentage(value),
      },
      {
        key: 'shares',
        title: 'Shares',
        dataIndex: 'shares',
        render: (value: number) => value,
      },
    ],
    []
  );

  const data = useMemo(() => {
    // Filter holdings by selected fund.
    const filteredHoldings = holdings.filter(
      (holding) => selectedFund === 'ALL' || holding.fund === selectedFund
    );
    let sortedHoldings = [];
    if (searchQuery) {
      sortedHoldings = [...filteredHoldings]
        .filter(
          (holding) =>
            holding.ticker && getSearchIndexTicker(holding, searchQuery) >= 0
        )
        .sort((holdingA, holdingB) =>
          getSearchIndexTicker(holdingA, searchQuery) <=
          getSearchIndexTicker(holdingB, searchQuery)
            ? -1
            : 1
        );

      if (sortedHoldings.length <= 0) {
        sortedHoldings = [...filteredHoldings]
          .filter(
            (holding) =>
              holding.ticker && getSearchIndexCompany(holding, searchQuery) >= 0
          )
          .sort((holdingA, holdingB) =>
            getSearchIndexCompany(holdingA, searchQuery) <=
            getSearchIndexCompany(holdingB, searchQuery)
              ? -1
              : 1
          );
      }
    } else {
      // Sort holdings by [fund, market value weight (descending)].
      sortedHoldings = [...filteredHoldings].sort((holdingA, holdingB) => {
        if (Funds.indexOf(holdingA.fund) === Funds.indexOf(holdingB.fund)) {
          return holdingA.mv_weight > holdingB.mv_weight ? -1 : 1;
        } else {
          return Funds.indexOf(holdingA.fund) < Funds.indexOf(holdingB.fund)
            ? -1
            : 1;
        }
      });
    }

    return sortedHoldings.map((holding, index) => ({
      ...holding,
      key: `${holding.fund as string}-${(holding.ticker || index) as string}`,
      index: index + 1,
    }));
  }, [holdings, selectedFund, searchQuery]);

  return (
    <Page>
      <Container>
        <Section>
          <Row isDesktopOrLaptop={true}>
            {Funds.map((fund) => (
              <QuoteCard key={fund} ticker={fund} quote={fundToQuote[fund]} />
            ))}
          </Row>
        </Section>
        <SectionSpace />
        <Section>
          <Row isDesktopOrLaptop={isDesktopOrLaptop}>
            <Radio.Group
              value={selectedFund}
              onChange={(event) => setSelectedFund(event.target.value)}
            >
              <Radio.Button value={'ALL'}>All</Radio.Button>
              {Funds.map((fund) => (
                <Radio.Button key={fund} value={fund}>
                  {fund}
                </Radio.Button>
              ))}
            </Radio.Group>
            <RowSpace />
            <Search>
              <Input
                allowClear
                size={'large'}
                placeholder={'Search (ex. TSLA)'}
                suffix={<SearchOutlined />}
                value={searchQuery}
                onChange={(event) => {
                  if (searchQuery === '') {
                    setSelectedFund('ALL');
                  }
                  setSearchQuery(event.target.value);
                }}
              />
            </Search>
          </Row>
        </Section>
        <SectionSpace />
        <Section hasPadding={false}>
          <TableWrapper>
            <Table pagination={false} columns={columns} dataSource={data} />
          </TableWrapper>
        </Section>
        <SectionSpace />
        <Section>
          <a href='https://clearbit.com'>Logos provided by Clearbit</a>
        </Section>
      </Container>
    </Page>
  );
}

export default HoldingsPage;
