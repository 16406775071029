export function formatShareCount(value: number) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let newValue = Math.abs(value);
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum += 1;
  }
  const isPositive = value > 0;
  return `${(newValue * (isPositive ? 1 : -1)).toPrecision(3)}${
    suffixes[suffixNum]
  }`;
}

export function formatCurrency(value: number) {
  return `${Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)}`;
}

export function formatPercentage(value: number) {
  return `${(value * 100).toFixed(2)}%`;
}
