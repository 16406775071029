import CandlestickChart from 'components/Chart/CandlestickChart';
import LineChart from 'components/Chart/LineChart';
import Page from 'components/Page/Page';
import { parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { GetCandlesticksByTicker, GetDiffsByTicker } from 'shared/api';
import { Candlestick, Diff } from 'shared/types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
  max-width: 1024px;
  padding-bottom: 64px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
  padding: 0px 16px;
`;

const SectionSpace = styled.div`
  height: 24px;
`;

const TitleText = styled.h2`
  margin: 0px;
  font-weight: 700;
`;

const SubtitleText = styled.h4`
  margin: 0px;
`;

function TickerPage() {
  const { ticker } = useParams<{ ticker: string }>();
  useTitle(`${ticker} | Ark Invest Holdings Tracker`);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  const [diffs, setDiffs] = useState<Diff[]>([]);
  const [candlesticks, setCandlesticks] = useState<Candlestick[]>([]);

  useEffect(() => {
    const fetchDataByTicker = async () => {
      // `ticker` currently is a fund plus a ticker.
      const actualTicker = ticker.split('-')[1];
      const candlesticks = await GetCandlesticksByTicker(actualTicker);
      const diffs = await GetDiffsByTicker(ticker);

      setCandlesticks(
        [...candlesticks].sort((candlestickA, candlestickB) =>
          parse(candlestickA.date, 'yyyy-MM-dd', new Date()) <
          parse(candlestickB.date, 'yyyy-MM-dd', new Date())
            ? -1
            : 1
        )
      );
      setDiffs(
        [...diffs].sort((diffA, diffB) =>
          parse(diffA.date, 'yyyy-MM-dd', new Date()) <
          parse(diffB.date, 'yyyy-MM-dd', new Date())
            ? -1
            : 1
        )
      );
    };
    fetchDataByTicker();
  }, [ticker]);

  return (
    <Page>
      <Container>
        <Section>
          <TitleText>{ticker}</TitleText>
          <SubtitleText>
            Note: data collection started on 01/22, holdings prior to that are
            not reflected below.
          </SubtitleText>
        </Section>
        <SectionSpace />
        <Section>
          <CandlestickChart
            height={isDesktopOrLaptop ? 480 : 360}
            title={'Buy / Sell Action Timeseries'}
            candlesticks={candlesticks}
            diffs={diffs}
          />
        </Section>
        <SectionSpace />
        <Section>
          <LineChart
            height={isDesktopOrLaptop ? 360 : 240}
            title={'% of Portfolio Timeseries'}
            candlesticks={candlesticks}
            diffs={diffs}
          />
        </Section>
      </Container>
    </Page>
  );
}

export default TickerPage;
