import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import DiffsWeightsCharts from 'components/DiffsWeightsCharts';
import Page from 'components/Page/Page';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetDiffsByDate } from 'shared/api';
import { formatDateStringUser } from 'shared/dates';
import { formatPercentage } from 'shared/numbers';
import { Diff } from 'shared/types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
  max-width: 1024px;
  padding-bottom: 64px;
`;

// const Section = styled.div`
//   display: flex;
//   flex-direction: column;

//   flex: 1;
//   width: 100%;
//   padding: 0px 16px;
// `;

interface SectionProps {
  hasPadding?: boolean;
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  ${({ hasPadding = true }: SectionProps) => hasPadding && 'padding: 0px 16px;'}
`;

const SectionSpace = styled.div`
  height: 48px;
`;

const SectionSpaceSmall = styled.div`
  height: 24px;
`;

const TableWrapper = styled.div`
  padding: 0px 16px;
`;

const HorizontalLine = styled.div`
  align-self: stretch;
  margin-top: 48px;
  border-top: 1px solid black;
`;

const TitleText = styled.h2`
  margin: 0px;
  font-weight: 700;
`;

const SectionText = styled.h2`
  margin: 0px;
  font-weight: 700;
`;

const TickerText = styled.span`
  font-weight: 500;
`;

const ChangeCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ChangeText = styled.span`
  margin-left: 8px;
`;

/**
 * {
 *   ticker: string;
 *   diff: {
 *     date: string;
 *     action: string;
 *     daily_share_diff: number;
 *     daily_share_weight_diff: number;
 *     normalized_share_weight_diff: number;
 *     shares: number;
 *     share_weight: number;
 *   } as Diff;
 * }
 */

function getColorForAction(action: string) {
  switch (action) {
    case 'New':
      return 'geekblue';
    case 'Buy':
      return 'green';
    case 'Sell':
      return 'red';
    case 'Exit':
    default:
      return 'orange';
  }
}

type AlignType = 'left' | 'center' | 'right';

// type Diff = {
//   ticker: string;
//   action: string;
//   daily_share_diff: number;
//   daily_share_weight_diff: number;
//   normalized_share_weight_diff: number;
//   shares: number;
//   share_weight: number;
// };

const Diffs = [
  {
    ticker: 'ARKK-BEAM',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-BIDU',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-EXAS',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-TXG',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-TWTR',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-TDOC',
    action: 'Buy',
    daily_share_diff: 5,
    daily_share_weight_diff: 0.05,
    normalized_share_weight_diff: 0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-PINS',
    action: 'Sell',
    daily_share_diff: 5,
    daily_share_weight_diff: -0.05,
    normalized_share_weight_diff: -0.03,
    shares: 10,
    share_weight: 0.02,
  },
  {
    ticker: 'ARKK-TSM',
    action: 'Sell',
    daily_share_diff: 5,
    daily_share_weight_diff: -0.05,
    normalized_share_weight_diff: -0.03,
    shares: 10,
    share_weight: 0.02,
  },
] as Diff[];

function DiffsPage() {
  const { date } = useParams<{ date: string }>();

  const [diffs, setDiffs] = useState<Diff[]>([]);

  useEffect(() => {
    const fetchDiffsByDate = async () => {
      const diffs = await GetDiffsByDate(date);
      setDiffs(diffs);
    };
    fetchDiffsByDate();
  }, [date]);

  const columns = useMemo(
    () => [
      {
        key: 'index',
        title: '#',
        dataIndex: 'index',
      },
      {
        key: 'ticker',
        title: 'Ticker',
        dataIndex: 'ticker',
        render: (value: string) => (
          <TickerText>{(value || '') as string}</TickerText>
        ),
      },
      {
        key: 'action',
        align: 'right' as AlignType,
        title: 'Action',
        dataIndex: 'action',
        render: (value: string) => (
          <Tag color={getColorForAction(value)}>{value}</Tag>
        ),
      },
      {
        key: 'normalized_share_weight_diff',
        align: 'right' as AlignType,
        title: 'Change',
        dataIndex: 'normalized_share_weight_diff',
        render: (value: number, record: Diff) => (
          <ChangeCell>
            {value >= 0 ? (
              <CaretUpOutlined
                style={{ color: getColorForAction(record.action) }}
              />
            ) : (
              <CaretDownOutlined
                style={{ color: getColorForAction(record.action) }}
              />
            )}
            <ChangeText style={{ color: getColorForAction(record.action) }}>
              {formatPercentage(Math.abs(value))}
            </ChangeText>
          </ChangeCell>
        ),
      },
      {
        key: 'share_weight',
        align: 'right' as AlignType,
        title: 'Close Share Weight',
        dataIndex: 'share_weight',
        render: (value: number) => formatPercentage(value),
      },
    ],
    []
  );

  const filteredDiffs = useMemo(
    () => diffs.filter((diff) => diff.fund === 'ARKK'),
    [diffs]
  );

  const data = useMemo(() => {
    return filteredDiffs.map((diff, index) => ({
      key: diff.ticker,
      index: index + 1,
      ticker: diff.ticker,
      fund: diff.fund,
      date: diff.date,
      action: diff.action,
      daily_share_diff: diff.daily_share_diff,
      daily_share_weight_diff: diff.daily_share_weight_diff,
      normalized_share_weight_diff: diff.normalized_share_weight_diff,
      shares: diff.shares,
      share_weight: diff.share_weight,
      company: diff.company,
      close_price: diff.close_price,
    }));
  }, [filteredDiffs]);

  return (
    <Page>
      <Container>
        <Section>
          <TitleText>
            {`Here are the buy / sell actions for date: ${formatDateStringUser(
              date
            )}.`}
          </TitleText>
        </Section>
        <SectionSpace />
        <Section>
          <SectionText>Summary of actions</SectionText>
        </Section>
        <SectionSpaceSmall />
        <Section hasPadding={false}>
          <TableWrapper>
            <Table pagination={false} columns={columns} dataSource={data} />
          </TableWrapper>
        </Section>
        <SectionSpace />
        <Section>
          <SectionText>Visualizations of each action</SectionText>
        </Section>
        <SectionSpaceSmall />
        {filteredDiffs.map((diff) => (
          <div key={`${diff.ticker}`}>
            <Section>
              <SectionText>{`${diff.ticker}: ${diff.action}`}</SectionText>
              <SectionText>
                {diff.normalized_share_weight_diff >= 0 ? (
                  <CaretUpOutlined
                    style={{ color: getColorForAction(diff.action) }}
                  />
                ) : (
                  <CaretDownOutlined
                    style={{ color: getColorForAction(diff.action) }}
                  />
                )}
                <ChangeText style={{ color: getColorForAction(diff.action) }}>
                  {formatPercentage(
                    Math.abs(diff.normalized_share_weight_diff)
                  )}
                </ChangeText>
              </SectionText>
              <SectionSpaceSmall />
              <DiffsWeightsCharts ticker={`${diff.fund}-${diff.ticker}`} />
              <HorizontalLine />
            </Section>
            <SectionSpace />
          </div>
        ))}
      </Container>
    </Page>
  );
}

export default DiffsPage;
