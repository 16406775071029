import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'shared/routes';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 24px 0px;
  margin-bottom: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  width: 100%;
`;

const Logo = styled.span`
  font-size: 30px;
  font-weight: 700;
`;

interface Props {
  children: ReactNode;
}

function Page({ children }: Props) {
  return (
    <Container>
      <Header>
        <Link to={Routes.root}>
          <Logo>Ark Insights</Logo>
        </Link>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
}

export default Page;
