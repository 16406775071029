export const TickerToCompanyUrl: { [key: string]: string } = {
  TSLA: 'tesla.com',
  ROKU: 'roku.com',
  TDOC: 'teladochealth.com',
  SQ: 'squareup.com',
  CRSP: 'crisprtx.com',
  NVTA: 'invitae.com',
  BIDU: 'baidu.com',
  Z: 'zillow.com',
  SPOT: 'spotify.com',
  SHOP: 'shopify.com',
  PRLB: 'protolabs.com',
  TCEHY: 'tencent.com',
  PYPL: 'paypal.com',
  PSTG: 'purestorage.com',
  ZM: 'zoom.us',
  TWLO: 'twilio.com',
  TWST: 'twistbioscience.com',
  EXAS: 'exactsciences.com',
  IOVA: 'iovance.com',
  DOCU: 'docusign.com',
  MTLS: 'materialise.com',
  NTLA: 'intelliatx.com',
  TSM: 'tsmc.com',
  U: 'unity.com',
  EDIT: 'editasmedicine.com',
  'TREE UW': 'lendingtree.com',
  PD: 'pagerduty.com',
  VCYT: 'veracyte.com',
  BEKE: '',
  TWOU: '2u.com',
  SSYS: 'stratasys.com',
  SE: 'sealimited.com',
  ICE: 'theice.com',
  NTDOY: 'nintendo.com',
  PACB: 'pacb.com',
  TXG: '10xgenomics.com',
  IRDM: 'iridium.com',
  PCAR: 'paccar.com',
  SNPS: 'synopsys.com',
  REGN: 'regeneron.com',
  NVS: 'novartis.com',
  MCRB: 'serestherapeutics.com',
  HUYA: '',
  CERS: 'cerus.com',
  SNAP: 'snapchat.com',
  NSTG: 'nanostring.com',
  TER: 'teradyne.com',
  CGEN: 'compugen.com',
  PINS: 'pinterest.com',
  SPLK: 'splunk.com',
  FATE: '',
  SYRS: 'syros.com',
  BEAM: 'beamtx.com',
  ONVO: 'organovo.com',
  TRMB: '',
  DE: '',
  JD: '',
  KTOS: 'kratosdefense.com',
  GOOG: 'google.com',
  NXPI: 'nxp.com',
  NNDM: 'nano-di.com',
  KMTUY: '',
  SPCE: 'virgingalactic.com',
  WKHS: 'workhorse.com',
  BYDDY: 'byd.com',
  CAT: 'caterpillar.com',
  NVDA: 'nvidia.com',
  AVAV: 'avinc.com',
  LMT: 'lmla.org',
  NIU: 'niu.com',
  XONE: 'exone.com',
  MGA: 'magna.com',
  TDY: '',
  ISRG: '',
  ANSS: '',
  XLNX: '',
  FLIR: '',
  ROK: '',
  RAVN: '',
  BABA: '',
  ESLT: '',
  AMZN: '',
  AAPL: '',
  ADSK: '',
  EXPC: '',
  HON: '',
  GBTC: 'grayscale.co',
  API: 'agora.io',
  NFLX: 'netflix.com',
  OPEN: 'opendoor.com',
  FSLY: 'fastly.com',
  FB: 'facebook.com',
  TWTR: 'twitter.com',
  PTON: 'onepeloton.com',
  CRM: 'salesforce.com',
  SI: 'silvergatebank.com',
  TTD: '',
  NET: 'cloudflare.com',
  PLTR: 'palantir.com',
  MELI: 'mercadolibre.com.mx',
  ADBE: 'adobe.com',
  DKNG: 'draftkings.com',
  ADYEY: '',
  HUBS: 'hubspot.com',
  CRWD: 'crowdstrike.com',
  TEAM: 'atlassian.com',
  OKTA: 'okta.com',
  LC: 'lendingclub.com',
  SKLZ: 'skillz.com',
  CDNA: 'caredx.com',
  RHHBY: '',
  VRTX: '',
  'TAK UN': '',
  'ARCT UQ': '',
  INCY: '',
  CSTL: '',
  PSNL: '',
  ACCD: '',
  IONS: '',
  SDGR: '',
  BLI: '',
  VEEV: '',
  TMO: '',
  GOOGL: 'abc.xyz',
  BMY: '',
  CDXS: '',
  SRPT: '',
  CLLS: '',
  PHR: '',
  ADPT: '',
  GH: '',
  AQB: '',
  LGVW: '',
  RPTX: '',
  SURF: '',
  MASS: '',
  EVGN: '',
  PSTI: '',
  ONEM: '',
  HIMS: 'forhims.com',
  ADYEN: '',
  '3690': '',
  '9923': '',
  '6060': '',
  '4689': '',
  WDAY: '',
  INTU: '',
  'TCS LI': '',
  '4477': '',
  GWRE: '',
  SCHW: '',
  PDD: '',
  DSY: '',
  HDB: '',
  LSPD: '',
  VRSK: '',
  BILL: '',
  MKTX: '',
  KSPI: '',
  DDD: '',
  DM: '',
  AM3D: '',
  ALMDG: '',
  PTC: '',
  STMN: '',
  RSW: '',
  HPQ: '',
  MSFT: 'microsoft.com',
  ALTR: '',
  ALGN: '',
  FARO: '',
  AME: '',
  HEXAB: '',
  BDT: '',
  UCTT: '',
  SIE: '',
  ML: '',
  OERL: '',
  KALU: '',
  GE: 'ge.com',
  UPS: 'ups.com',
  NUVA: '',
  'MOG/A': '',
  AJRD: '',
  'MDT UN': '',
  SYK: '',
  LECO: '',
  ARNC: '',
  PYR: '',
  SGL: '',
  KODK: '',
  MMM: '',
  HXL: '',
  'XRX UN': '',
  EVK: '',
  ATI: '',
  VOE: '',
  '3402': '',
  DSM: '',
  SAND: '',
  EMN: '',
  HEN3: '',
  CRS: '',
  AKE: '',
  DD: '',
  GILT: '',
  ENLV: '',
  PERI: '',
  INCR: '',
  EMCO: '',
  ROBO: '',
  NNOX: '',
  'URGN UQ': '',
  DRIO: '',
  ALLT: '',
  RADA: '',
  PYPD: '',
  BSEN: '',
  RDHL: '',
  ITRN: '',
  FVRR: '',
  CAMT: '',
  KMDA: '',
  INMD: '',
  WIX: '',
  PLSN: '',
  DANE: '',
  RDWR: '',
  GMDA: '',
  ECP: '',
  SILC: '',
  MTRX: '',
  TEVA: '',
  HLAN: '',
  SPNS: '',
  NICE: '',
  ONE: '',
  TSEM: '',
  ARD: '',
  PTNR: '',
  AUDC: '',
  TARO: '',
  KRNT: '',
  MLTM: '',
  CYBR: '',
  BEZQ: '',
  HAML: '',
  SEDG: '',
  FTAL: '',
  FROG: '',
  NVMI: '',
  MGIC: '',
  CRNT: '',
  BATM: '',
  CHKP: '',
  ITMR: '',
  BIMCM: '',
  CEL: '',
  AYLA: '',
  BVXV: '',
};
