import { formatCurrency } from 'shared/numbers';
import { Quote } from 'shared/types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  min-width: 96px;
`;

const TickerText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: gray;
`;

const PriceText = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

interface Props {
  ticker: string;
  quote: Quote | null;
}

function QuoteCard({ ticker, quote }: Props) {
  return (
    <Container>
      <TickerText>{ticker}</TickerText>
      <PriceText>
        {quote?.price ? formatCurrency(quote.price) : 'Loading...'}
      </PriceText>
    </Container>
  );
}

export default QuoteCard;
